/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    h3: "h3",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Content Partials"), "\n", React.createElement(_components.h2, null, "Why"), "\n", React.createElement(_components.p, null, "This dierectory contains mdx files that can be included in other mdx files as well as react components. These partials are used to allow content managers to easily update content that is used in multiple places in one spot."), "\n", React.createElement(_components.h2, null, "How"), "\n", React.createElement(_components.p, null, "Just add a file with title case and a .mdx extension."), "\n", React.createElement(_components.h3, null, "How to inlude in react components"), "\n", React.createElement(_components.p, null, "To easily access the content partial in a react component, add the ", React.createElement(_components.code, null, "includeId"), " variable in the frontmatter. A graphql query can then be made to easily filter and fine the content partial to inlcude in your component."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Also as a good practice please add the graphql query for the content partial to the constants file so its easily accessed and the query doesnt have to be written in various files."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
