/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*
This is currently just a place for defining the terms. The object is currently queried by the DefaultLayout component that then hydrates the GlossaryContext.
The GlossaryTerm component then calls an action on the GlossaryContext to retrieve the definition for its tooltip display.
*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  return React.createElement(React.Fragment);
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
