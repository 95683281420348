/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components), {Box, Container, Grid, DoiLogoImg, Link} = _components;
  if (!Box) _missingMdxReference("Box", true);
  if (!Container) _missingMdxReference("Container", true);
  if (!DoiLogoImg) _missingMdxReference("DoiLogoImg", true);
  if (!Grid) _missingMdxReference("Grid", true);
  if (!Link) _missingMdxReference("Link", true);
  return React.createElement(Box, {
    component: "footer",
    display: "flex",
    alignItems: "center",
    bgcolor: "info.dark",
    pt: 4,
    pb: 4,
    style: {
      position: 'relative',
      zIndex: 275,
      top: 6
    },
    displayPrint: "none"
  }, React.createElement(Container, {
    maxWidth: "lg"
  }, React.createElement(Grid, {
    container: true
  }, React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 2
  }, React.createElement(Box, {
    p: 4,
    display: {
      xs: 'none',
      sm: 'block'
    }
  }, React.createElement(DoiLogoImg))), React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 7
  }, React.createElement(Box, {
    color: "white"
  }, React.createElement("h2", null, "Built in the open"), React.createElement(Box, {
    mr: 4
  }, React.createElement(_components.p, null, "This site\n", React.createElement(Link, {
    href: "https://github.com/DOI-ONRR/nrrd/releases",
    style: {
      color: 'white'
    }
  }, props.data.site.siteMetadata.version, " ", React.createElement(OpenInNewIcon, {
    style: {
      fontSize: 12,
      fill: 'white'
    }
  })), " is powered by ", React.createElement(Link, {
    href: "/downloads",
    style: {
      color: 'white'
    }
  }, "open data"), " and\n", React.createElement(Link, {
    href: "https://github.com/DOI-ONRR/nrrd",
    style: {
      color: 'white'
    }
  }, "source code ", React.createElement(OpenInNewIcon, {
    style: {
      fontSize: 12,
      fill: 'white'
    }
  })), ".\nWe welcome contributions and comments on ", React.createElement(Link, {
    href: "https://github.com/DOI-ONRR/nrrd/issues",
    style: {
      color: 'white'
    }
  }, "GitHub\n", React.createElement(OpenInNewIcon, {
    style: {
      fontSize: 12,
      fill: 'white'
    }
  })), ". We write about how we work on this\nsite on ", React.createElement(Link, {
    href: "https://blog-nrrd.doi.gov",
    style: {
      color: 'white'
    }
  }, "our team's blog ", React.createElement(OpenInNewIcon, {
    style: {
      fontSize: 12,
      fill: 'white'
    }
  })), ".")), React.createElement(Box, {
    color: "white",
    fontSize: 'caption.fontSize',
    mt: 4
  }, React.createElement(_components.p, null, React.createElement(Link, {
    href: "/",
    style: {
      color: 'white'
    }
  }, "Home"), " | \n", React.createElement(Link, {
    href: "/about",
    style: {
      color: 'white'
    }
  }, "About"), " | \n", React.createElement(Link, {
    href: "https://www.doi.gov/",
    style: {
      color: 'white'
    }
  }, "Department of the Interior ", React.createElement(OpenInNewIcon, {
    style: {
      fontSize: 12,
      fill: 'white'
    }
  })), " | \n", React.createElement(Link, {
    href: "https://www.doi.gov/privacy",
    style: {
      color: 'white'
    }
  }, "Privacy Policy ", React.createElement(OpenInNewIcon, {
    style: {
      fontSize: 12,
      fill: 'white'
    }
  })), " | \n", React.createElement(Link, {
    href: "https://www.doi.gov/foia",
    style: {
      color: 'white'
    }
  }, "FOIA ", React.createElement(OpenInNewIcon, {
    style: {
      fontSize: 12,
      fill: 'white'
    }
  })), " | \n", React.createElement(Link, {
    href: "https://www.usa.gov/",
    style: {
      color: 'white'
    }
  }, "USA.gov ", React.createElement(OpenInNewIcon, {
    style: {
      fontSize: 12,
      fill: 'white'
    }
  })), " | \n", React.createElement(Link, {
    href: "https://www.doioig.gov/",
    style: {
      color: 'white'
    }
  }, "Inspector General ", React.createElement(OpenInNewIcon, {
    style: {
      fontSize: 12,
      fill: 'white'
    }
  })), " | \n", React.createElement(Link, {
    href: "https://www.doi.gov/pmb/eeo/reports-repository",
    style: {
      color: 'white'
    }
  }, "No Fear Act ", React.createElement(OpenInNewIcon, {
    style: {
      fontSize: 12,
      fill: 'white'
    }
  })), " | \n", React.createElement(Link, {
    href: "/vdp",
    style: {
      color: 'white'
    }
  }, "Vulnerability Disclosure Policy"), " | \n", React.createElement(Link, {
    href: "/information-quality",
    style: {
      color: 'white'
    }
  }, "Information Quality"), " | \n", React.createElement(Link, {
    href: "/accessibility",
    style: {
      color: 'white'
    }
  }, "Accessibility"), " | \n", React.createElement(Link, {
    href: "/plain-language",
    style: {
      color: 'white'
    }
  }, "Plain Language"))))), React.createElement(Grid, {
    item: true,
    xs: 12,
    sm: 3
  }, React.createElement(Box, {
    pt: 4,
    color: "white",
    fontSize: 'caption.fontSize'
  }, `${props.data.site.siteMetadata.officeName},`, React.createElement("br"), `${props.data.site.siteMetadata.informationDataManagement.name}`, React.createElement("br"), `${props.data.site.siteMetadata.informationDataManagement.street}`, React.createElement("br"), `${props.data.site.siteMetadata.informationDataManagement.city} ${props.data.site.siteMetadata.informationDataManagement.zip}`, React.createElement("br"), React.createElement(Link, {
    href: `mailto:${props.data.site.siteMetadata.informationDataManagement.email}`,
    style: {
      color: 'white'
    }
  }, props.data.site.siteMetadata.informationDataManagement.email), React.createElement("br"), React.createElement(Link, {
    href: `mailto:${props.data.site.siteMetadata.informationDataManagement.contact}`,
    style: {
      color: 'white'
    }
  }, props.data.site.siteMetadata.informationDataManagement.contact))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
