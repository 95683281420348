/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const {Box, WarningIconImg} = Object.assign({}, _provideComponents(), props.components);
  if (!Box) _missingMdxReference("Box", true);
  if (!WarningIconImg) _missingMdxReference("WarningIconImg", true);
  return React.createElement(Box, {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "#fef1d2",
    height: "40px",
    mt: 1,
    mb: 1
  }, React.createElement(WarningIconImg), React.createElement(Box, {
    fontSize: "caption.fontSize",
    m: {
      sm: 0.5,
      md: 1
    }
  }, "Due to the government shutdown, we are not able to update this site. We will resume work on the site when the shutdown ends."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
