/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {DATA_FILTER_CONSTANTS as DFC} from '../../constants';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components), {Container, Typography, Box, FederalLandPercentage, Grid, Link} = _components;
  if (!Box) _missingMdxReference("Box", true);
  if (!Container) _missingMdxReference("Container", true);
  if (!FederalLandPercentage) _missingMdxReference("FederalLandPercentage", true);
  if (!Grid) _missingMdxReference("Grid", true);
  if (!Link) _missingMdxReference("Link", true);
  if (!Typography) _missingMdxReference("Typography", true);
  return React.createElement(Container, {
    maxWidth: "lg"
  }, React.createElement(Typography, {
    variant: "h2",
    component: "h1"
  }, props.pageContext.frontmatter.title), React.createElement(Box, null, React.createElement(_components.p, null, "This page provides information about energy and minerals produced on federal land in ", props.pageContext.frontmatter.title, ". Federal land represents\n", React.createElement(FederalLandPercentage, {
    stateOrArea: props.pageContext.frontmatter.unique_id
  }), " of all land in ", props.pageContext.frontmatter.title, ".")), React.createElement(Box, {
    component: "p"
  }, React.createElement(_components.p, null, "The Office of Natural Resources Revenue (ONRR) collects rent, royalty, and bonus payments from companies that lease federal land. Some of the money collected is then\ndisbursed to the state. States then distribute the funds as part of their budgets, but we don't have data about how the states spend the funds.")), React.createElement(Box, {
    mt: 2
  }, React.createElement(Grid, {
    container: true,
    spacing: 1
  }, React.createElement(Grid, {
    item: true,
    xs: 6
  }, React.createElement(Link, {
    href: `/explore?dataType=${DFC.REVENUE}&location=NF,${props.pageContext.frontmatter.unique_id}/`,
    linkType: "ExploreData"
  }, React.createElement(_components.p, null, "Explore revenue data in ", props.pageContext.frontmatter.title)), React.createElement(Link, {
    href: `/explore?dataType=${DFC.DISBURSEMENT}&location=NF,${props.pageContext.frontmatter.unique_id}/`,
    linkType: "ExploreData"
  }, React.createElement(_components.p, null, "Explore disbursements data in ", props.pageContext.frontmatter.title)), React.createElement(Link, {
    href: `/explore?dataType=${DFC.PRODUCTION}&location=NF,${props.pageContext.frontmatter.unique_id}/`,
    linkType: "ExploreData"
  }, React.createElement(_components.p, null, "Explore production data in ", props.pageContext.frontmatter.title))), React.createElement(Grid, {
    item: true,
    xs: 6
  }, React.createElement(Link, {
    href: `/query-data?dataType=${DFC.REVENUE}&stateOffshoreName=${props.pageContext.frontmatter.title}`,
    linkType: "FilterTable"
  }, React.createElement(_components.p, null, "Query revenue data in ", props.pageContext.frontmatter.title)), React.createElement(Link, {
    href: `/query-data?dataType=${DFC.DISBURSEMENT}&stateOffshoreName=${props.pageContext.frontmatter.title}
          &usStateName=${props.pageContext.frontmatter.title}`,
    linkType: "FilterTable"
  }, React.createElement(_components.p, null, "Query disbursements data in ", props.pageContext.frontmatter.title)), React.createElement(Link, {
    href: `/query-data?dataType=${DFC.PRODUCTION}&stateOffshoreName=${props.pageContext.frontmatter.title}`,
    linkType: "FilterTable"
  }, React.createElement(_components.p, null, "Query production data in ", props.pageContext.frontmatter.title))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
