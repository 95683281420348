/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const {Box, UsFlagImg} = Object.assign({}, _provideComponents(), props.components);
  if (!Box) _missingMdxReference("Box", true);
  if (!UsFlagImg) _missingMdxReference("UsFlagImg", true);
  return React.createElement(Box, {
    displayPrint: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "#062135",
    height: "30px",
    style: {
      transition: 'height .1s ease',
      position: 'relative',
      zIndex: 250,
      color: 'white'
    }
  }, React.createElement(UsFlagImg, {
    alt: 'U.S. flag signifying that this is a United States Federal Government website'
  }), React.createElement(Box, {
    fontSize: "caption.fontSize",
    m: {
      sm: 0.5,
      md: 1
    }
  }, "An official website of the U.S. government"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
