/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components), {Link} = _components;
  if (!Link) _missingMdxReference("Link", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "What's new"), "\n", React.createElement(_components.p, null, "Updated geographical references to reflect the new name Gulf of America for the area formerly called Gulf of Mexico, following Executive Order 14172."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "March 27, 2025 changes:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Added ", React.createElement(Link, {
    href: "/how-revenue-works/gomesa/",
    linkType: "default"
  }, "FY 25 Gulf of America OCS disbursements")), "\n"), "\n", React.createElement(_components.p, null, "Review our ", React.createElement(_components.a, {
    href: "https://github.com/ONRR/nrrd/releases"
  }, "full release details"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
