exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-accessibility-mdx": () => import("./../../../src/pages/accessibility.mdx" /* webpackChunkName: "component---src-pages-accessibility-mdx" */),
  "component---src-pages-data-by-state-offshore-region-mdx": () => import("./../../../src/pages/data-by-state-offshore-region.mdx" /* webpackChunkName: "component---src-pages-data-by-state-offshore-region-mdx" */),
  "component---src-pages-downloads-disbursements-by-month-mdx": () => import("./../../../src/pages/downloads/disbursements-by-month.mdx" /* webpackChunkName: "component---src-pages-downloads-disbursements-by-month-mdx" */),
  "component---src-pages-downloads-disbursements-mdx": () => import("./../../../src/pages/downloads/disbursements.mdx" /* webpackChunkName: "component---src-pages-downloads-disbursements-mdx" */),
  "component---src-pages-downloads-federal-revenue-by-company-mdx": () => import("./../../../src/pages/downloads/federal-revenue-by-company.mdx" /* webpackChunkName: "component---src-pages-downloads-federal-revenue-by-company-mdx" */),
  "component---src-pages-downloads-federal-sales-mdx": () => import("./../../../src/pages/downloads/federal-sales.mdx" /* webpackChunkName: "component---src-pages-downloads-federal-sales-mdx" */),
  "component---src-pages-downloads-index-mdx": () => import("./../../../src/pages/downloads/index.mdx" /* webpackChunkName: "component---src-pages-downloads-index-mdx" */),
  "component---src-pages-downloads-partials-federal-sales-product-codes-table-js": () => import("./../../../src/pages/downloads/partials/FederalSalesProductCodesTable.js" /* webpackChunkName: "component---src-pages-downloads-partials-federal-sales-product-codes-table-js" */),
  "component---src-pages-downloads-partials-federal-sales-transaction-codes-table-js": () => import("./../../../src/pages/downloads/partials/FederalSalesTransactionCodesTable.js" /* webpackChunkName: "component---src-pages-downloads-partials-federal-sales-transaction-codes-table-js" */),
  "component---src-pages-downloads-production-by-disposition-mdx": () => import("./../../../src/pages/downloads/production-by-disposition.mdx" /* webpackChunkName: "component---src-pages-downloads-production-by-disposition-mdx" */),
  "component---src-pages-downloads-production-by-month-mdx": () => import("./../../../src/pages/downloads/production-by-month.mdx" /* webpackChunkName: "component---src-pages-downloads-production-by-month-mdx" */),
  "component---src-pages-downloads-production-mdx": () => import("./../../../src/pages/downloads/production.mdx" /* webpackChunkName: "component---src-pages-downloads-production-mdx" */),
  "component---src-pages-downloads-revenue-mdx": () => import("./../../../src/pages/downloads/revenue.mdx" /* webpackChunkName: "component---src-pages-downloads-revenue-mdx" */),
  "component---src-pages-explore-index-mdx": () => import("./../../../src/pages/explore/index.mdx" /* webpackChunkName: "component---src-pages-explore-index-mdx" */),
  "component---src-pages-explore-offshore-regions-alaska-mdx": () => import("./../../../src/pages/explore/offshore-regions/alaska.mdx" /* webpackChunkName: "component---src-pages-explore-offshore-regions-alaska-mdx" */),
  "component---src-pages-explore-offshore-regions-atlantic-mdx": () => import("./../../../src/pages/explore/offshore-regions/atlantic.mdx" /* webpackChunkName: "component---src-pages-explore-offshore-regions-atlantic-mdx" */),
  "component---src-pages-explore-offshore-regions-gulf-mdx": () => import("./../../../src/pages/explore/offshore-regions/gulf.mdx" /* webpackChunkName: "component---src-pages-explore-offshore-regions-gulf-mdx" */),
  "component---src-pages-explore-offshore-regions-pacific-mdx": () => import("./../../../src/pages/explore/offshore-regions/pacific.mdx" /* webpackChunkName: "component---src-pages-explore-offshore-regions-pacific-mdx" */),
  "component---src-pages-explore-states-ak-mdx": () => import("./../../../src/pages/explore/states/AK.mdx" /* webpackChunkName: "component---src-pages-explore-states-ak-mdx" */),
  "component---src-pages-explore-states-al-mdx": () => import("./../../../src/pages/explore/states/AL.mdx" /* webpackChunkName: "component---src-pages-explore-states-al-mdx" */),
  "component---src-pages-explore-states-ar-mdx": () => import("./../../../src/pages/explore/states/AR.mdx" /* webpackChunkName: "component---src-pages-explore-states-ar-mdx" */),
  "component---src-pages-explore-states-az-mdx": () => import("./../../../src/pages/explore/states/AZ.mdx" /* webpackChunkName: "component---src-pages-explore-states-az-mdx" */),
  "component---src-pages-explore-states-ca-mdx": () => import("./../../../src/pages/explore/states/CA.mdx" /* webpackChunkName: "component---src-pages-explore-states-ca-mdx" */),
  "component---src-pages-explore-states-co-mdx": () => import("./../../../src/pages/explore/states/CO.mdx" /* webpackChunkName: "component---src-pages-explore-states-co-mdx" */),
  "component---src-pages-explore-states-fl-mdx": () => import("./../../../src/pages/explore/states/FL.mdx" /* webpackChunkName: "component---src-pages-explore-states-fl-mdx" */),
  "component---src-pages-explore-states-id-mdx": () => import("./../../../src/pages/explore/states/ID.mdx" /* webpackChunkName: "component---src-pages-explore-states-id-mdx" */),
  "component---src-pages-explore-states-il-mdx": () => import("./../../../src/pages/explore/states/IL.mdx" /* webpackChunkName: "component---src-pages-explore-states-il-mdx" */),
  "component---src-pages-explore-states-in-mdx": () => import("./../../../src/pages/explore/states/IN.mdx" /* webpackChunkName: "component---src-pages-explore-states-in-mdx" */),
  "component---src-pages-explore-states-ks-mdx": () => import("./../../../src/pages/explore/states/KS.mdx" /* webpackChunkName: "component---src-pages-explore-states-ks-mdx" */),
  "component---src-pages-explore-states-ky-mdx": () => import("./../../../src/pages/explore/states/KY.mdx" /* webpackChunkName: "component---src-pages-explore-states-ky-mdx" */),
  "component---src-pages-explore-states-la-mdx": () => import("./../../../src/pages/explore/states/LA.mdx" /* webpackChunkName: "component---src-pages-explore-states-la-mdx" */),
  "component---src-pages-explore-states-md-mdx": () => import("./../../../src/pages/explore/states/MD.mdx" /* webpackChunkName: "component---src-pages-explore-states-md-mdx" */),
  "component---src-pages-explore-states-mi-mdx": () => import("./../../../src/pages/explore/states/MI.mdx" /* webpackChunkName: "component---src-pages-explore-states-mi-mdx" */),
  "component---src-pages-explore-states-mn-mdx": () => import("./../../../src/pages/explore/states/MN.mdx" /* webpackChunkName: "component---src-pages-explore-states-mn-mdx" */),
  "component---src-pages-explore-states-mo-mdx": () => import("./../../../src/pages/explore/states/MO.mdx" /* webpackChunkName: "component---src-pages-explore-states-mo-mdx" */),
  "component---src-pages-explore-states-ms-mdx": () => import("./../../../src/pages/explore/states/MS.mdx" /* webpackChunkName: "component---src-pages-explore-states-ms-mdx" */),
  "component---src-pages-explore-states-mt-mdx": () => import("./../../../src/pages/explore/states/MT.mdx" /* webpackChunkName: "component---src-pages-explore-states-mt-mdx" */),
  "component---src-pages-explore-states-nc-mdx": () => import("./../../../src/pages/explore/states/NC.mdx" /* webpackChunkName: "component---src-pages-explore-states-nc-mdx" */),
  "component---src-pages-explore-states-nd-mdx": () => import("./../../../src/pages/explore/states/ND.mdx" /* webpackChunkName: "component---src-pages-explore-states-nd-mdx" */),
  "component---src-pages-explore-states-ne-mdx": () => import("./../../../src/pages/explore/states/NE.mdx" /* webpackChunkName: "component---src-pages-explore-states-ne-mdx" */),
  "component---src-pages-explore-states-nm-mdx": () => import("./../../../src/pages/explore/states/NM.mdx" /* webpackChunkName: "component---src-pages-explore-states-nm-mdx" */),
  "component---src-pages-explore-states-nv-mdx": () => import("./../../../src/pages/explore/states/NV.mdx" /* webpackChunkName: "component---src-pages-explore-states-nv-mdx" */),
  "component---src-pages-explore-states-ny-mdx": () => import("./../../../src/pages/explore/states/NY.mdx" /* webpackChunkName: "component---src-pages-explore-states-ny-mdx" */),
  "component---src-pages-explore-states-oh-mdx": () => import("./../../../src/pages/explore/states/OH.mdx" /* webpackChunkName: "component---src-pages-explore-states-oh-mdx" */),
  "component---src-pages-explore-states-ok-mdx": () => import("./../../../src/pages/explore/states/OK.mdx" /* webpackChunkName: "component---src-pages-explore-states-ok-mdx" */),
  "component---src-pages-explore-states-or-mdx": () => import("./../../../src/pages/explore/states/OR.mdx" /* webpackChunkName: "component---src-pages-explore-states-or-mdx" */),
  "component---src-pages-explore-states-pa-mdx": () => import("./../../../src/pages/explore/states/PA.mdx" /* webpackChunkName: "component---src-pages-explore-states-pa-mdx" */),
  "component---src-pages-explore-states-sc-mdx": () => import("./../../../src/pages/explore/states/SC.mdx" /* webpackChunkName: "component---src-pages-explore-states-sc-mdx" */),
  "component---src-pages-explore-states-sd-mdx": () => import("./../../../src/pages/explore/states/SD.mdx" /* webpackChunkName: "component---src-pages-explore-states-sd-mdx" */),
  "component---src-pages-explore-states-tx-mdx": () => import("./../../../src/pages/explore/states/TX.mdx" /* webpackChunkName: "component---src-pages-explore-states-tx-mdx" */),
  "component---src-pages-explore-states-ut-mdx": () => import("./../../../src/pages/explore/states/UT.mdx" /* webpackChunkName: "component---src-pages-explore-states-ut-mdx" */),
  "component---src-pages-explore-states-va-mdx": () => import("./../../../src/pages/explore/states/VA.mdx" /* webpackChunkName: "component---src-pages-explore-states-va-mdx" */),
  "component---src-pages-explore-states-wa-mdx": () => import("./../../../src/pages/explore/states/WA.mdx" /* webpackChunkName: "component---src-pages-explore-states-wa-mdx" */),
  "component---src-pages-explore-states-wi-mdx": () => import("./../../../src/pages/explore/states/WI.mdx" /* webpackChunkName: "component---src-pages-explore-states-wi-mdx" */),
  "component---src-pages-explore-states-wv-mdx": () => import("./../../../src/pages/explore/states/WV.mdx" /* webpackChunkName: "component---src-pages-explore-states-wv-mdx" */),
  "component---src-pages-explore-states-wy-mdx": () => import("./../../../src/pages/explore/states/WY.mdx" /* webpackChunkName: "component---src-pages-explore-states-wy-mdx" */),
  "component---src-pages-fact-sheet-mdx": () => import("./../../../src/pages/fact-sheet.mdx" /* webpackChunkName: "component---src-pages-fact-sheet-mdx" */),
  "component---src-pages-glossary-mdx": () => import("./../../../src/pages/glossary.mdx" /* webpackChunkName: "component---src-pages-glossary-mdx" */),
  "component---src-pages-how-revenue-works-aml-reclamation-program-mdx": () => import("./../../../src/pages/how-revenue-works/aml-reclamation-program.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-aml-reclamation-program-mdx" */),
  "component---src-pages-how-revenue-works-audits-and-assurances-mdx": () => import("./../../../src/pages/how-revenue-works/audits-and-assurances.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-audits-and-assurances-mdx" */),
  "component---src-pages-how-revenue-works-coal-excise-tax-mdx": () => import("./../../../src/pages/how-revenue-works/coal-excise-tax.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-coal-excise-tax-mdx" */),
  "component---src-pages-how-revenue-works-coal-mdx": () => import("./../../../src/pages/how-revenue-works/coal.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-coal-mdx" */),
  "component---src-pages-how-revenue-works-disbursements-mdx": () => import("./../../../src/pages/how-revenue-works/disbursements.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-disbursements-mdx" */),
  "component---src-pages-how-revenue-works-federal-laws-mdx": () => import("./../../../src/pages/how-revenue-works/federal-laws.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-federal-laws-mdx" */),
  "component---src-pages-how-revenue-works-federal-reforms-mdx": () => import("./../../../src/pages/how-revenue-works/federal-reforms.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-federal-reforms-mdx" */),
  "component---src-pages-how-revenue-works-gomesa-mdx": () => import("./../../../src/pages/how-revenue-works/gomesa.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-gomesa-mdx" */),
  "component---src-pages-how-revenue-works-hpf-mdx": () => import("./../../../src/pages/how-revenue-works/hpf.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-hpf-mdx" */),
  "component---src-pages-how-revenue-works-index-mdx": () => import("./../../../src/pages/how-revenue-works/index.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-index-mdx" */),
  "component---src-pages-how-revenue-works-lwcf-mdx": () => import("./../../../src/pages/how-revenue-works/lwcf.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-lwcf-mdx" */),
  "component---src-pages-how-revenue-works-minerals-mdx": () => import("./../../../src/pages/how-revenue-works/minerals.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-minerals-mdx" */),
  "component---src-pages-how-revenue-works-native-american-ownership-governance-mdx": () => import("./../../../src/pages/how-revenue-works/native-american-ownership-governance.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-native-american-ownership-governance-mdx" */),
  "component---src-pages-how-revenue-works-native-american-production-mdx": () => import("./../../../src/pages/how-revenue-works/native-american-production.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-native-american-production-mdx" */),
  "component---src-pages-how-revenue-works-native-american-revenue-mdx": () => import("./../../../src/pages/how-revenue-works/native-american-revenue.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-native-american-revenue-mdx" */),
  "component---src-pages-how-revenue-works-offshore-oil-gas-mdx": () => import("./../../../src/pages/how-revenue-works/offshore-oil-gas.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-offshore-oil-gas-mdx" */),
  "component---src-pages-how-revenue-works-offshore-renewables-mdx": () => import("./../../../src/pages/how-revenue-works/offshore-renewables.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-offshore-renewables-mdx" */),
  "component---src-pages-how-revenue-works-onshore-oil-gas-mdx": () => import("./../../../src/pages/how-revenue-works/onshore-oil-gas.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-onshore-oil-gas-mdx" */),
  "component---src-pages-how-revenue-works-onshore-renewables-mdx": () => import("./../../../src/pages/how-revenue-works/onshore-renewables.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-onshore-renewables-mdx" */),
  "component---src-pages-how-revenue-works-ownership-mdx": () => import("./../../../src/pages/how-revenue-works/ownership.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-ownership-mdx" */),
  "component---src-pages-how-revenue-works-reclamation-mdx": () => import("./../../../src/pages/how-revenue-works/reclamation.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-reclamation-mdx" */),
  "component---src-pages-how-revenue-works-revenues-mdx": () => import("./../../../src/pages/how-revenue-works/revenues.mdx" /* webpackChunkName: "component---src-pages-how-revenue-works-revenues-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-information-quality-mdx": () => import("./../../../src/pages/information-quality.mdx" /* webpackChunkName: "component---src-pages-information-quality-mdx" */),
  "component---src-pages-patterns-components-index-mdx": () => import("./../../../src/pages/patterns/components/index.mdx" /* webpackChunkName: "component---src-pages-patterns-components-index-mdx" */),
  "component---src-pages-patterns-index-mdx": () => import("./../../../src/pages/patterns/index.mdx" /* webpackChunkName: "component---src-pages-patterns-index-mdx" */),
  "component---src-pages-patterns-visual-styles-color-mdx": () => import("./../../../src/pages/patterns/visual-styles/color.mdx" /* webpackChunkName: "component---src-pages-patterns-visual-styles-color-mdx" */),
  "component---src-pages-patterns-visual-styles-iconography-mdx": () => import("./../../../src/pages/patterns/visual-styles/iconography.mdx" /* webpackChunkName: "component---src-pages-patterns-visual-styles-iconography-mdx" */),
  "component---src-pages-patterns-visual-styles-typography-mdx": () => import("./../../../src/pages/patterns/visual-styles/typography.mdx" /* webpackChunkName: "component---src-pages-patterns-visual-styles-typography-mdx" */),
  "component---src-pages-plain-language-mdx": () => import("./../../../src/pages/plain-language.mdx" /* webpackChunkName: "component---src-pages-plain-language-mdx" */),
  "component---src-pages-query-data-mdx": () => import("./../../../src/pages/query-data.mdx" /* webpackChunkName: "component---src-pages-query-data-mdx" */),
  "component---src-pages-search-results-mdx": () => import("./../../../src/pages/search-results.mdx" /* webpackChunkName: "component---src-pages-search-results-mdx" */),
  "component---src-pages-vdp-mdx": () => import("./../../../src/pages/vdp.mdx" /* webpackChunkName: "component---src-pages-vdp-mdx" */)
}

