/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components), {Box, GlossaryTerm} = _components;
  if (!Box) _missingMdxReference("Box", true);
  if (!GlossaryTerm) _missingMdxReference("GlossaryTerm", true);
  return React.createElement(Box, {
    fontSize: 'caption.fontSize',
    ml: 1,
    display: "inline-block"
  }, React.createElement(_components.p, null, "This content was created as part of the ", React.createElement(GlossaryTerm, null, "USEITI"), ". We no longer update this resource."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
